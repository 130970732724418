import { Card, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  paddedPaper: {
    backgroundColor: "#FFFFFFCC",
  },
}));

export default function WeddingSection(props) {
  const classes = useStyles();
  
  return (
    <Card {...props} elevation={5} className={classes.paddedPaper}>
      {props.children}
    </Card>
  );
}