import { CardContent, Typography } from "@material-ui/core";
import WeddingSection from "./WeddingSection";

export default function Bothell(props) {
  return (
    <WeddingSection {...props}>
      <CardContent>
        <Typography gutterBottom variant="h5">What to do near Bothell</Typography>
        <Typography>
          Bothell Landing - A sizable public park & wooded area that connects to 38 miles of paved walking and bike paths.
          <ul>
            <li>Rent Kayaks and boat up the Sammamish River,</li>
            <li>
              Rent bikes or scooters to explore the trails! The Woodinville wine tasting region is a quick 4.5 mile jaunt up 
              the Sammamish River Trail.    For the more ambitious, it's 14-20 miles on the Burke-Gilman trail
              into downtown Seattle.
            </li>
            <li><a href="https://www.bothellhistoricalmuseum.org/">Bothell Historical Musuem</a>.  Open Sundays 1-4.  Currently closed for Covid, but could be open by July.</li>
          </ul>
        </Typography>
        <Typography>
          Woodinville Wine District - home to tasting rooms for many of
          Washington's wineries. (
          <a href="https://woodinvillewinecountry.com/">Woodinville Wine</a>).
          <ul>
          <li>
            You can also find cider tasting, mead, a handful of breweries, and
            even Woodinville Whiskey Company in the same area.
          </li>
          </ul>
        </Typography>
        <Typography>
          Other Local Drink and Dining we Enjoy
          <ul>
            <li>
              The Bine - Cozy and comfortable, right on Main Street in downtown
              Bothell. Fantastic comfort food, large selection of drinks on tap.
            </li>
            <li>
              The Hop and Hound - Also on Main Street, a great spot to drink
              a craft beer and relax. Food trucks outside every Friday!
            </li>
            <li>
              Bay Leaf Bar & Grill - Upscale Indian food with good vegetarian options.
            </li>
            <li>
              Sushi Zone - Conveyor belt sushi.  Have you ever wanted to stuff yourself silly with sushi?  Happy hour starts at 9:00pm and all dishes are 1.50!  (Confirm they're open for dine-in before going)
            </li>
            <li>
              Bento Teriyaki - Chicken teriyaki is a <a href="https://youtu.be/VDemCWOooZk">quintessential Seattle experience</a>!  Bento Teriyaki is our favorite in Bothell.
            </li>
            <li>
              Toshi's Teriyaki - The original Seattle Teriyaki.  Their flagship establishment is right up the road in Mill Creek, a 15 min drive away.  See more details in the <a href="https://youtu.be/8dLCT1IHXTA">Travel channel feature</a>.
            </li>
            <li>
              Twisted Cuban - in Woodinville. Great if you're looking for a Cubano.
            </li>
          </ul>
        </Typography>
        <Typography>
          Getting to Seattle - The bus stop a short walk from McMenamins will
          take you to Downtown Seattle (45 minutes).  See the travel section for more info
          on how to purchase tickets or ask Karen and Sam.
        </Typography>
        <Typography>
          Hiking - If you're renting a car and are looking to take a day trip to
          do some hiking, head in pretty much any direction and you will hit
          some trailheads. We'd recommend the Fragerance Lake trailhead near
          Bellingham -- our first hike together!
          <ul>
          <li>Olympic National Park - The largest temperate rainforest in the world.  It's full of ferns as well as mossy trees and is a sight to behold if you've never been.</li>
          <li>Cascades - these are not so different from the Olympics and host a variety of hikes much closer and easier to do as a day trip.</li>
          <li>Mt Rainier - if lush, alpine meadows are more you thing, this is where you want to be!</li>
          <li>
            Most of Washington's (non-national park) trailheads require a{" "}
            <a href="https://www.discoverpass.wa.gov/faq.aspx">
              Discover Pass.
            </a>{" "}
            Single day passes ($10) can be printed from home (or your hotel),
            and annual passes ($30) can be purchased at stores all over
            Washington.
          </li>
          </ul>
        </Typography>
      </CardContent>
    </WeddingSection>
  );
}
