import WeddingSection from "./WeddingSection";
import {
  CardContent,
  Typography,
} from "@material-ui/core";

export default function Accommodations(props) {  
    return (
      <WeddingSection {...props}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Accommodations
          </Typography>
          <Typography variant="h6" component="h2">
            McMenamin's Anderson School
          </Typography>
          <Typography>
            <a href="https://www.mcmenamins.com/anderson-school">Anderson School</a> is a destination all on its own,
            even if you don't stay here.  The hotel is a half-mile walk to Karen &amp; Sam's making it the closest accommodation,
             and it has the most personality to
            boot!  McMenamin's is a local hotel/restaurant/bar/microbrewery/distillery chain that specializes in turning 
            interesting building complexes into one-of-a-kind entertainment destinations.  
            Anderson school used to be - you 
            guessed it - a school!  Specifically Bothell's high school.  Because of this, it has a sprawling campus which houses 
            several restaurants, a movie theater, an indoor swimming pool, and multiple themed bars.  Highlights include "The Shed,"
            which is a scotch bar and "North Shore Lagoon," which is a tiki bar 
            above the pool.  Because McMenamins is a microbrewery, cidery, and distillery, so there's always something unique to try.
            Karen recommends the Ruby Ale or any of their seasonal ciders.
            It has a lovely outdoor seating area with one of the nicest landscapings in Bothell, including numerous
            firepits which are perfect for Bothell's chilly summer evenings.  Full disclosure: You can find better food, but the drinks
            and atmosphere are what it's all about!
          </Typography>
          <Typography variant="h6" component="h2">
            Holiday Inn Express
          </Typography>
          <Typography>
            <a href="https://www.ihg.com/holidayinnexpress/hotels/us/en/bothell/botwa/hoteldetail">This</a> is our pick for close, budget friendly, and well-rated!
          </Typography>
          <Typography variant="h6" component="h2">
            Willows Lodge
          </Typography>
          <Typography>
            <a href="https://www.willowslodge.com/">Willows Lodge</a> is a luxury option in the heart of the Woodinville wine-tasting region.  Aside from proximity to
            local tasting rooms, Willows Lodge houses a spa and two restaurants, one of which offers a seasonal, local
            9-course tasting menu.
          </Typography>
        </CardContent>
      </WeddingSection>
    );
  }