import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Button,
  Radio,
  RadioGroup,
  TextField,
  CardContent,
  Typography,
} from "@material-ui/core"; //importing material ui component
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import WeddingSection from "./WeddingSection";

export default class RSVP extends React.Component {
  allowSubmit = false;

  constructor(props) {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.capchaFilled = this.capchaFilled.bind(this);
    this.capchaExpired = this.capchaExpired.bind(this);
  }

  capchaFilled() {
    this.allowSubmit = true;
  }

  capchaExpired() {
    this.allowSubmit = false;
  }

  handleSubmit(e) {
    if (this.allowSubmit) return true;
    e.preventDefault();
    alert("Fill in the capcha!");
  }

  render() {
    return (
      <WeddingSection>
        <CardContent>
          <Typography gutterBottom variant="h5">
            RSVP
          </Typography>
          <form
            /*style={{"textAlign": "center"}}*/
            id="fs-frm"
            name="basic-rsvp-form"
            acceptCharset="utf-8"
            action="https://formspree.io/f/mayandek"
            method="post"
            onSubmit={this.handleSubmit}
          >
            <FormControl fullWidth margin="none">
              <FormLabel component="legend">Name(s)</FormLabel>
              <TextField
                id="names"
                name="names"
                fullWidth
                margin="normal"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                style={{ marginTop: 8 }}
                required
                placeholder="Please list you, your guest, and any children!"
              />
              <br />
              <FormLabel component="legend">Will you Attend?</FormLabel>
              <RadioGroup>
                <FormControlLabel
                  id="attending"
                  name="attending"
                  value="Attending"
                  control={<Radio required />}
                  label="Twokittying me? I wouldn't miss it! (Yes)"
                />
                <FormControlLabel
                  id="attending"
                  name="attending"
                  value="Not Attending"
                  control={<Radio required />}
                  label="Attila you what: Can't make it. (No)"
                />
              </RadioGroup>
              <br />
              <FormLabel>
                We're catering BBQ! If you'd like a special accommodation for
                vegetarian, vegan, or any allergies, please specify.
              </FormLabel>
              <TextField
                id="comments"
                label=""
                name="comments"
                fullWidth
                margin="normal"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                style={{ marginTop: 8 }}
                multiline
                rows={4}
              />

              <input
                type="hidden"
                name="_subject"
                id="email-subject"
                value="RSVP"
              />
              <br />
              <ReCAPTCHA
                sitekey="6LftY7QaAAAAAK2hTvUfanySF3ABNBNWzLU0FGia"
                onChange={this.capchaFilled}
                onExpired={this.capchaExpired}
                onErrored={this.capchaExpired}
                size="normal"
              />
              <br />
              <Button
                color="secondary"
                type="submit"
                value="RSVP"
                variant="contained"
              >
                RSVP
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </WeddingSection>
    );
  }
}
