import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const MainTheme = createMuiTheme({
  typography: {
    fontWeightRegular: 450,
    fontWeightMedium: 450,
    fontWeightBold: 650,
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#777777",
    },
    secondary: {
      main: "#5599DD",
    },
  },

});

export default responsiveFontSizes(MainTheme);