import WeddingSection from "./WeddingSection";
import { CardContent, Typography } from "@material-ui/core";

export default function Travel(props) {
  return (
    <WeddingSection {...props}>
      <CardContent>
        <Typography gutterBottom variant="h5">Travel</Typography>
        <Typography variant="h6">By Air</Typography>
        <Typography>
          SeaTac is the largest airport in the area. It's located south of Seattle.
          Paine Field Airport in Everett, north of Seattle, is the closest to Bothell.
          However it has limited flights and is only serviced by Alaska Airlines and United Airlines.
        </Typography>
        <Typography variant="h6">By Car</Typography>
        <Typography>
          SeaTac Airport to Bothell is about 30 miles by car. Paine Field
          Airport to Bothell is about 13 miles.  Downtown Seattle to Bothell is 20 miles.
        </Typography>
        <Typography variant="h6">By Public Transit</Typography>
        <Typography>
          The lightrail from SeaTac to downtown Seattle is pleasant and
          efficient and, at $3 per person, is the most economical option
          available. It takes approximately 35 minutes. If you want to continue
          on public transportation to Bothell, you can catch the 522 from
          downtown. The 522 takes about 45 minutes to run from Seattle to
          Bothell (when there's no traffic), and has stops 0.5 miles from Karen
          &amp; Sam's house as well as 0.3 miles from McMenamin's Anderson School
          hotel. The bus ticket costs $3.25 per person.
        </Typography>
        <Typography>
          When taking public transit, you can load up an Orca card with money
          ahead of time, or you can download the{" "}
          <a href="https://kingcounty.gov/depts/transportation/metro/fares-orca/transit-go-ticket.aspx">
            Transit GO Ticket app
          </a>
          . Note that when using this app, you need to know what system the bus
          is on. The 522 route is on the Sounds Transit System.
        </Typography>
        <Typography variant="h6">By Sea</Typography>
        <Typography>
          If you're traveling by sea, please let us know! We'd like to join you! ;)
        </Typography>
      </CardContent>
    </WeddingSection>
  );
}
