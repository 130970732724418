import React from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";

import { Link as SmoothLink } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  inactive: {
    '&::before': {
      position: 'absolute',
      bottom: 9,
      left: '50%',
      height: 2,
      width: 0,
      marginLeft: 0,
      transitionDuration: '.5s',
      backgroundColor: '#272B2F',
      content: "''",
    },
  },
  active: {
    '&::before': {
      position: 'absolute',
      bottom: 9,
      left: '50%',
      height: 2,
      width: 'calc(100% - 14px)',
      marginLeft: 'calc(-50% + 7px)',
      backgroundColor: '#272B2F',
      content: "''",
    },
  },
  menu: {
    paddingRight: "10px",
  },
  linkHoverAnimation: {
    '&::before': {
      position: 'absolute',
      bottom: 9,
      left: 'calc(50%)',
      width: 0,
      height: 2,
      marginLeft: 0,
      backgroundColor: '#272B2F',
      content: "''",
      transitionDuration: '.5s'
    },
    '&:hover::before': {
      width: 'calc(100% - 14px)',
      marginLeft: 'calc(-50% + 7px)'
    }
  }
}));

function NavDesktop(props) {
  const classes = useStyles();
  const links = props.links.map((link, i) => {

    let divider = null;
    if (i < props.links.length-1)
      divider = <span>•</span>;


    return (
      <Grid item key={link.anchor}>

        <Button
          component={SmoothLink}
          variant="text"
          to={link.anchor}
          smooth
          spy
          offset={-130}
          duration= {(dist)=>{ return 1000+ Math.abs(dist/3) }}
       
          className={classes.inactive}
          activeClass={classes.active}
        >
          <Typography className={classes.linkHoverAnimation}>
          {link.text}
          </Typography>
        </Button>
        {divider}
      </Grid>

    );
  });

  return (
    <Grid container justify="flex-end" className={classes.menu}>
      {links}
    </Grid>
  );
}

export default NavDesktop;
