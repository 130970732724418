import React from "react";
import {
  SwipeableDrawer,
  Button,
  IconButton,
  List,
  ListItem,
} from "@material-ui/core";

import { Link as SmoothLink } from "react-scroll";
import MenuIcon from "@material-ui/icons/Menu";

function NavMobile(props) {
  const [state, setState] = React.useState({
    open: false,
  });

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ open: isOpen });
  };

  const links = props.links.map((link) => {
    return (
      <ListItem key={link.anchor}>
        <Button
          style={{justifyContent: "flex-start"}}
          to={link.anchor}
          offset={-72}
          smooth
          spy
          component={SmoothLink}
          duration= {(dist)=>{ return 1000+ Math.abs(dist/3) }}
          onClick={toggleDrawer(false)}
        >
          {link.text}
        </Button>
      </ListItem>
    );
  });

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon fontSize="large" />
      </IconButton>

      <SwipeableDrawer
        anchor={"right"}
        open={state["open"]}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <List>{links}</List>
      </SwipeableDrawer>
    </div>
  );
}

export default NavMobile;
