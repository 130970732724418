import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Hidden,
} from "@material-ui/core";

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from "@material-ui/core/styles";
import NavDesktop from "./NavDesktop.js";
import NavMobile from "./NavMobile.js";

const headerTheme = createMuiTheme({
  typography: {
    fontWeightRegular: 450,
    fontWeightMedium: 450,
    fontWeightBold: 550,
    body1: {
      fontWeight: 450,
    },
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#FFFFFFDD",
    },
    secondary: {
      main: "#20B2EB",
    },
  },
});

const links = [
  { text: "About", anchor: "about" },
  { text: "Venue", anchor: "venue" },
  { text: "Travel", anchor: "travel" },
  { text: "Accommodations", anchor: "accommodations" },
  { text: "Around Bothell", anchor: "bothell" },
  { text: "FAQ", anchor: "faq" },
  { text: "RSVP", anchor: "rsvp" },
];

function Header() {
  return (
    <React.Fragment>
      <ThemeProvider theme={responsiveFontSizes(headerTheme)}>
        <AppBar position="fixed" elevation={3}>
          <Container id="header" maxWidth="lg">
            <Toolbar>
              <Box display="flex" flexGrow={1}>
                <Typography noWrap>Karen & Sam Wedding</Typography>
              </Box>
              <Hidden only={["xs", "sm"]}>
                <NavDesktop links={links}/>
              </Hidden>

              <Hidden only={["md", "lg", "xl"]}>
                <NavMobile links={links} />
              </Hidden>
            </Toolbar>
          </Container>
        </AppBar>
        <Toolbar /> {/* Spacer to move content out from under header */}
        <br />
      </ThemeProvider>
    </React.Fragment>
  );
}
export default Header;
