import {
  Grid,
  ThemeProvider,
  CardMedia,
  Container,
  makeStyles,
  Card,
  Hidden,
} from "@material-ui/core"; //importing material ui component

import Header from "./Header.js";
import RSVP from "./RSVP.js";
import MainTheme from "./MainTheme.js";
import AboutUs from "./AboutUs.js";
import Bothell from "./Bothell.js";
import Venue from "./Venue.js";
import Travel from "./Travel.js";
import Accommodations from "./Accommodations.js";
import FAQ from "./FAQ";

import samAndKarenBeach from "./images/sam-and-karen-beach-sunset.jpeg";
import samAndKarenWedding from "./images/karen-and-sam-wedding.jpeg";
import samAndKarenAirplane from "./images/sam-and-karen-airplane.jpeg";
import samAndKarenBridge from "./images/sam-and-karen-bridge.jpeg";
import samAndKarenBuildingNight from "./images/sam-and-karen-building-night.jpeg";
import samAndKarenStaring from "./images/sam-and-karen-staring.jpeg";
import samAndKarenTent from "./images/sam-and-karen-tent.jpeg";

import attila1 from "./images/attila1.jpg";
//import attila2 from "./images/attila2.jpg";
import attilaAndFlowers from "./images/attila-and-flowers.jpeg";
import babyKitties from "./images/baby-kitties.jpeg";
import coffeeAndCats from "./images/coffee-and-cats.jpeg";
import attilaScreaming from "./images/attila-screaming.jpeg";
import travelTwokitty from "./images/travel-twokitty.jpeg";
import twokittyLick from "./images/twokitty-lick.jpeg";
import upsideDownTwokitty from "./images/upside-down-twokitty.jpeg";
import twokittyFace from "./images/twokitty-face.jpeg";

// New Kitties
import kittiesOnBlanket from "./images/both-kitties-on-blanket.png";
import twoKittyPresent from "./images/twokitty-present.png";
import attillaCone from "./images/attila-in-cone.png";
import twokittyScreaming from "./images/twokitty-screaming.png";
import kittiesLookingWindow from "./images/kitties-on-stool.png";
import twokittyInBall from "./images/twokitty-ball.png";
import brisketMaking from "./images/brisket-making.png";
import attilaLickingTwokitty from "./images/attila-licking-twokitty.png";
import kittiesSnuggling from "./images/kitties-snuggling.png";
//import kittiesKarenSleeping from "./images/kitties-karen-sleeping.png"

// New Sam & Karen
import shedInProgress from "./images/shed-in-progress.png";
import shedFinished from "./images/shed-finished.png";
import samAndKarenKissingInSpain from "./images/sam-and-karen-kissing-spain.png";
import samAndKarenWindyBeach from "./images/sam-and-karen-windy-beach.png";
import samAndKarenHikingFragranceLake from "./images/hiking-fragrance-lake.jpeg";
import samAndKarenGondola from "./images/sam-and-karen-gondola.jpeg";
import samAndKarenDrenchedHiking1 from "./images/sam-and-karen-drenched-hiking.jpeg";
import samAndKaren360Bridge from "./images/sam-and-karen-360-bridge.jpeg";
import samAndKarenDrenchedHiking2 from "./images/sam-and-karen-drenched-hiking2.jpeg";
import samAndKarenSoccer from "./images/sam-and-karen-soccer-game.jpeg";
import samAndKarenSoudner from "./images/sam-and-karen-sounders.jpeg";
import samAndKarenWaterfallKissing from "./images/waterfall-kissin.jpeg";
import samAndKarenPaintingHouse from "./images/painting-house.jpeg";
import samAndKarenInLargeChair from "./images/in-large-chair.jpeg";
import samAndKarenHikingOrcas from "./images/sam-and-karen-hiking-orcas.jpeg";
import samAndKarenBudapestRestaurant from "./images/budapest-restaurant.png";
import samAndKarenViennaHotel from "./images/vienna-hotel.png";
import samAndKarenPragueStatue from "./images/prague-statue.png";

const useStyles = makeStyles({
  square: {
    margin: 5,
    paddingTop: "100%",
    height: 0,
  },
  tall4by3: {
    margin: 5,
    paddingTop: "133%", // 3x4
    height: 0,
  },
  wide4by3: {
    margin: 5,
    paddingTop: "75%", // 4x3
    height: 0,
  },
  wide16by9: {
    margin: 5,
    height: 0,
    paddingTop: "56.25%", // 16:9,
  },
});

function App() {
  const classes = useStyles();

  return (
    <div id="main">
      <Header />
      <ThemeProvider theme={MainTheme}>
        <div>
          <Container maxWidth="lg">
            <Grid
              container
              item
              md={12}
              spacing={3}
              justify="center"
              alignItems="center"
            >
              {/* ABOUT US */}
              <Grid item md={4} sm={12} xs={12} spacing={3} id="about">
                <AboutUs />
              </Grid>
              <Grid item md={8} sm={6} xs={12}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.wide4by3}
                    image={samAndKarenBeach}
                    title="Walking on the Beach in San Sebastian"
                  />
                </Card>
              </Grid>

              {/* PHOTO BREAK between about us and venue */}
              <Hidden only={["xs"]}>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKaren360Bridge}
                      title="360 Bridge, Austin, TX"
                    />
                  </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenHikingOrcas}
                      title="Hiking on Sucia Island after Sailing There!"
                    />
                  </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenSoccer}
                      title="Watching Real Madrid in... Madrid"
                    />
                  </Card>
                </Grid>
              </Hidden>

              {/* VENUE */}
              <Grid item md={4} sm={12} xs={12}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.tall4by3}
                    image={samAndKarenWedding}
                    title="COVID Wedding"
                  />
                </Card>
              </Grid>

              <Grid item md={8} sm={12} xs={12} id="venue">
                <Venue />
              </Grid>

              {/* TRAVEL */}
              <Grid item md={8} sm={12} xs={12} id="travel">
                <Travel />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.tall4by3}
                    image={samAndKarenAirplane}
                    title="🍹🍹!"
                  />
                </Card>
              </Grid>

              {/* PHOTO BREAK between travel and accommodations */}
              <Hidden only={["sm", "xs"]}>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenPaintingHouse}
                      title="Painting the New House"
                    />
                  </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={shedInProgress}
                      title="Sam and Karen Building a Shed"
                    />
                  </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={shedFinished}
                      title="Shed (mostly) Complete!"
                    />
                  </Card>
                </Grid>
              </Hidden>

              {/* ACCOMMODATIONS */}
              <Grid item md={4} sm={6} xs={12}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.tall4by3}
                    image={samAndKarenTent}
                    title="Camping in Wenatchee National Forest"
                  />
                </Card>
              </Grid>
              <Grid id="accommodations" item md={8} sm={12} xs={12}>
                <Accommodations />
              </Grid>

              {/* PHOTO BREAK between accommodations and around bothell */}
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={samAndKarenStaring}
                    title="At a Pub in Speyside"
                  />
                </Card>
              </Grid>
              <Hidden only={["sm", "xs"]}>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenSoudner}
                      title="Go Sounders!"
                    />
                  </Card>
                </Grid>
              </Hidden>
              <Hidden only={["xs"]}>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenGondola}
                      title="Gondola Ride in Banff National Park"
                    />
                  </Card>
                </Grid>
              </Hidden>

              {/* AROUND BOTHELL */}
              <Grid id="bothell" item md={8} sm={12} xs={12}>
                <Bothell />
              </Grid>
              <Grid item container md={4} sm={12} xs={12} spacing={3}>
                <Grid item md={12} xs={12}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenKissingInSpain}
                      title="San Sebastian, Spain"
                    />
                  </Card>
                </Grid>

                <Hidden only={["sm", "xs"]}>
                  <Grid item md={12} xs={12}>
                    <Card elevation={5}>
                      {/* 16:9 */}
                      <CardMedia
                        className={classes.wide16by9}
                        image={samAndKarenBuildingNight}
                        title="Bordeaux, France"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Card elevation={5}>
                      {/* 16:9 */}
                      <CardMedia
                        className={classes.square}
                        image={samAndKarenHikingFragranceLake}
                        title="Hiking to Fragrance Lake"
                      />
                    </Card>
                  </Grid>
                </Hidden>
              </Grid>

              {/* PHOTO BREAK between around bothell and faq */}
              <Hidden only={["sm", "xs"]}>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenDrenchedHiking1}
                      title="Lake Lousie, Banff National Park"
                    />
                  </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenWindyBeach}
                      title="Biscarrosse, France"
                    />
                  </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenDrenchedHiking2}
                      title="Near the Fairy Pools, Isle of Skye"
                    />
                  </Card>
                </Grid>
              </Hidden>

              {/* FREQUENTLY ASKED QUESTIONS */}
              <Hidden only={["sm", "xs"]}>
                <Grid item container md={4} sm={6} xs={12} spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Card elevation={5}>
                      {/* 16:9 */}
                      <CardMedia
                        className={classes.square}
                        image={samAndKarenInLargeChair}
                        title="Sam and Karen in a Large Chair at Radium Hot Springs"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Card elevation={5}>
                      <CardMedia
                        className={classes.wide16by9}
                        image={samAndKarenWaterfallKissing}
                        title="Camping in the Wenatchee National Forest"
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Hidden>
              <Grid id="faq" item md={8} sm={12} xs={12}>
                <FAQ />
              </Grid>

              <Hidden only={["md", "lg", "xl"]}>
                <Grid item md={6} sm={12} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenBridge}
                      title="Chilling in Aberlour"
                    />
                  </Card>
                </Grid>
              </Hidden>

              {/* PHOTO BREAK between around faq and rsvp */}
              <Hidden only={["sm", "xs"]}>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenPragueStatue}
                      title="Pretending to be a Statue in Prague"
                    />
                  </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenBudapestRestaurant}
                      title="Dining in Budapest"
                    />
                  </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenViennaHotel}
                      title="Airbnb Rooftop in Vienna"
                    />
                  </Card>
                </Grid>
              </Hidden>

              {/* RSVP */}
              <Grid item md={6} id="rsvp">
                <RSVP />
              </Grid>

              <Hidden only={["sm", "xs"]}>
                <Grid item md={6} sm={12} xs={12} spacing={3}>
                  <Card elevation={5}>
                    {/* 16:9 */}
                    <CardMedia
                      className={classes.square}
                      image={samAndKarenBridge}
                      title="Chilling in Aberlour!"
                    />
                  </Card>
                </Grid>
              </Hidden>

              {/* KITTY PHOTOS */}
              <Grid item md={4} sm={6} xs={12} id="cats">
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={attilaAndFlowers}
                    title="Our Kitties! (Attila)"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={twokittyLick}
                    title="Our Kitties! (Little Twokitty Bopus Jr)"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={attila1}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={twokittyFace}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={coffeeAndCats}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={upsideDownTwokitty}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={attilaScreaming}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={travelTwokitty}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={babyKitties}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={kittiesOnBlanket}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={twoKittyPresent}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={attillaCone}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={twokittyScreaming}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={kittiesLookingWindow}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={twokittyInBall}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={brisketMaking}
                    title="Trimming a Brisket!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={attilaLickingTwokitty}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12} spacing={3}>
                <Card elevation={5}>
                  {/* 16:9 */}
                  <CardMedia
                    className={classes.square}
                    image={kittiesSnuggling}
                    title="Our Kitties!"
                  />
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
