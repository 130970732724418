import WeddingSection from "./WeddingSection";
import backyard from "./images/backyard.jpg";
import {
  CardContent,
  //CardHeader,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  media: {
    margin:5,
    height: 0,
    paddingTop: "56.25%", // 16:9,
  },
});

export default function Venue(props) {
  const classes = useStyles();

  return (
    <WeddingSection {...props}>
      <CardContent>
        <Typography gutterBottom variant="h5">
          Venue
        </Typography>
        <Typography>
          We got married in our backyard last July!  Come help us celebrate one year later. 
         </Typography>
         <Typography>
          Join us for a ceremony followed by an evening of
          backyard chilling, dinner, drinks, and lawn games!  Street parking
          is available, no permit necessary.
        </Typography>
      </CardContent>

      {/* this one is 16:9 */}
      <CardMedia
        className={classes.media}
        image={backyard}
        title="Our Backyard!"
      />

    </WeddingSection>
  );
}
