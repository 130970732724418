import WeddingSection from "./WeddingSection";
import {
  CardContent,
  Typography,
} from "@material-ui/core";


export default function FAQ(props) {

  return (
    <WeddingSection {...props}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">Frequently Asked Questions</Typography>
        <Typography gutterBottom variant="h6" component="h2">Schedule</Typography>
                <ul>
                    <li>4:00 Vow renewal ceremony</li>
                    <li>Dinner and cake cutting to follow</li>
                </ul>
        <Typography gutterBottom variant="h6" component="h2">
          Attire
        </Typography>
        <Typography>
          Cocktail attire or "smart casual."  We recommend shoes that do
          well on grass, as most of our backyard is grass!  Evening temperatures can drop into the 60's, so we also recommend
          a light jacket.
        </Typography>
        <Typography gutterBottom variant="h6" component="h2">
          Food
        </Typography>
        <Typography>
          We're catering Texas-style BBQ!
          <br/>
          Main course includes:
          <ul>
              <li>Brisket</li>
              <li>Cheddar Jalapeno Hot Links</li>
              <li>3rd Meat TBD</li>
          </ul>
          Sides include:
          <ul>
            <li>Cole Slaw</li>
            <li>Baked Beans</li>
            <li>3rd Side TBD</li>
          </ul>
          Please let us know if you'd like special accommodations for vegetarian, vegan, or any allergies.
        </Typography>
        <Typography gutterBottom variant="h6" component="h2">
          Gift Registry
        </Typography>
        <Typography>
          No gifts are necessary!  We're just looking forward to seeing you here!
        </Typography>
      </CardContent>
    </WeddingSection>
  );
}
