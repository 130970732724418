import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

ReactDOM.render(
  // <React.StrictMode> this because i dont know how to fix smoothlink errors
    <App />
  // </React.StrictMode>,
  , document.getElementById("root")
);
