import WeddingSection from "./WeddingSection";
import { CardContent, Divider, Typography } from "@material-ui/core";

export default function AboutUs(props) {
  return (
    <WeddingSection {...props}>
      <CardContent>
      <Typography gutterBottom variant="h5">About Us</Typography>
      <Typography>
        Sam, understanding the true way to a Texan's heart, 
        asked Karen to a BBQ joint in Ballard as their first date after meeting on Tinder.  Karen was disappointed 
        in the dry brisket, but enamored with Sam's dry sense of humor.  
      </Typography>
      <Typography>
        Since then they've 
        spent their time together traveling, eating good food (BBQ, mac & cheese),
        drinking good drink (Scotch, cider), smoking brisket, making wine, enjoying board games as well as trivia 
        with friends, DIY-ing their way through a myriad of home (and shedbuilding) projects, 
        and, of course, loving on their two adorable cats Attila and Little Twokitty Bopus Jr.
        <br/>
        <br/>
        <Divider/>
        <br/>
        tl;dr - Sam and Karen met on Tinder.  4 years later they got married.  
        Attila and Twokitty are great.  So are BBQ & Scotch.  The end.
     </Typography>
     </CardContent>
    </WeddingSection>
  );
}
